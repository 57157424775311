/* Global styling */
main {
  margin-top: 40px;
}
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  color: #d1d1d1; /* Ljusgrå text */
  background-color: #202a36; /* Mörkgråblå bakgrund */
  overflow-x: hidden;
  scroll-behavior: smooth;
}

h1,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-family: "Playfair Display", serif;
  font-size: 3rem;
  color: #ffc107; /* Gyllengul */
  text-align: center;
  margin-bottom: 20px;
  text-shadow: 2px 2px 8px rgba(255, 193, 7, 0.4);
}

p {
  font-size: 20px;
  line-height: 1.7;
  color: white; /* Mjuk ljusgrå */
  text-align: center;
  max-width: 700px;
  margin: 15px auto;
}

/* Navbar styling */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: rgba(15, 32, 39, 0.95); /* Ursprunglig marinblå */
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease, padding 0.3s ease,
    box-shadow 0.3s ease;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
}

/* Transparent effect when scrolled */
.navbar.scrolled {
  background: rgba(15, 32, 39, 0.7); /* Mer transparent vid scrollning */
  box-shadow: none;
}

.navbar-logo {
  color: #ffc107; /* Gyllengul för logotyp */
  font-size: 1.7rem;
  font-weight: bold;
  text-shadow: 2px 2px 8px rgba(255, 193, 7, 0.4);
}

.navbar-list {
  display: flex;
  gap: 1.5rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-list li {
  color: white; /* Vit text */
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-right: 20px;
}

.navbar-list li:hover {
  color: #ffc107; /* Gyllengul hover-effekt */
}

.menu-toggle {
  display: none;
  font-size: 1.5rem;
  color: #ffc107;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

@media (max-width: 768px) {
  .navbar-list {
    flex-direction: column;
    background-color: #202a36;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    gap: 1rem;
    display: none;
    border-radius: 0 0 10px 10px;
  }

  .navbar-list.open {
    display: flex;
  }

  .menu-toggle {
    display: block;
  }
}

/* Padding for main content */
main {
  padding-top: 80px;
}

/* Start section */
.start-section {
  position: relative;
  padding: 60px 5%;
  text-align: center;
  color: #ffffff;
  background: #283a4d; /* Mörk gråblå för boxar */
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  max-width: 70%;
  margin: 0 auto;
  border: 2px solid rgba(255, 255, 255, 0.2);
  animation: fadeIn 2s ease-in-out;
}

.start-overlay {
  position: absolute;
  top: 10;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent);
  z-index: 1;
}

.start-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  color: #d1d1d1;
  animation: fadeIn 1.5s ease-out;
}

.start-content h1 {
  font-size: 3.5rem;
  font-family: "Playfair Display", serif;
  color: transparent;
  background: linear-gradient(90deg, #ffc107, #ffb300); /* Gyllengul gradient */
  background-clip: text;
  -webkit-background-clip: text;
  letter-spacing: 1px;
  animation: slideIn 1.5s ease-out forwards;
  margin-bottom: 20px;
}

.start-content p {
  font-size: 22px;
  line-height: 1.8;
  color: white; /* Vit text */
  margin-bottom: 30px;
}

/* CTA Button for Explore Services */
.cta-button {
  background: linear-gradient(90deg, #009688, #00796b); /* Turkosblå gradient */
  color: #ffffff;
  padding: 15px 40px;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 8px 20px rgba(0, 150, 136, 0.4);
}

.cta-button:hover {
  background: linear-gradient(90deg, #00796b, #004d40); /* Mörkare turkos */
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 150, 136, 0.5);
}

/* Service section */
.service-section {
  padding: 60px 8%;
  background: #283a4d; /* Samma mörk gråblå för enhetlighet */
  color: #d1d1d1;
  margin: 40px auto;
  border-radius: 15px;
  max-width: 800px;
  min-height: 40vh;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow: hidden;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.service-section:hover {
  transform: scale(1.02);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.service-title {
  font-size: 2.8rem;
  font-family: "Playfair Display", serif;
  color: #ffc107; /* Gyllengul */
  margin-bottom: 10px;
  letter-spacing: 1px;
  animation: fadeIn 1s ease-out forwards;
}

.service-description {
  font-size: 1.2rem;
  color: white; /* Vit text */
  line-height: 1.8;
  max-width: 750px;
  margin: 0 auto;
}

/* Image gallery */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* 200px är exempel, justera efter behov */
  gap: 15px;
  margin-top: 40px;
}

.image-card {
  position: relative;
  width: 100%; /* Tar full bredd i kolumnen */
  height: 300px; /* En fast höjd för alla bilder, justera vid behov */
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-card:hover {
  transform: scale(1.03);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.3);
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.image-card:hover .image {
  transform: scale(1.1);
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  padding: 15px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-card:hover .image-overlay {
  opacity: 1;
}

.image-caption {
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
}

/* Buttons */
.button-group {
  margin-top: 30px;
}

.btn {
  background: linear-gradient(90deg, #009688, #00796b); /* Turkosblå gradient */
  color: #ffffff;
  padding: 12px 30px;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

.btn-show-more:hover,
.btn-show-less:hover {
  background: linear-gradient(90deg, #00796b, #004d40); /* Mörkare turkos */
  transform: translateY(-3px);
}

/* Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive design */
@media (max-width: 1024px) {
  .start-section,
  .service-section {
    padding: 40px 4%;
    max-width: 90%;
    min-height: 60vh;
  }
}

@media (max-width: 768px) {
  .start-section,
  .service-section {
    padding: 30px 3%;
    max-width: 95%;
    min-height: 50vh;
  }

  .service-title {
    font-size: 2rem;
  }

  .service-description {
    font-size: 1rem;
  }

  .btn {
    font-size: 1rem;
    padding: 10px 25px;
  }
}

@media (max-width: 480px) {
  .start-section,
  .service-section {
    padding: 20px 2%;
    max-width: 100%;
    min-height: 40vh;
  }

  .start-content h1,
  .service-title {
    font-size: 1.8rem;
  }

  .start-content p,
  .service-description {
    font-size: 0.9rem;
  }
}

/* Global styling for sections */
.section-container {
  padding: 80px 5%;
  max-width: 70%;
  margin: 0 auto 60px auto; /* Avstånd mellan sektionerna */
  text-align: center;
}

/* Contact Section */
.contact-section {
  color: #d1d1d1; /* Ljusgrå text för kontaktsektionen */
}
/* About Us Section */
.about-section {
  padding: 50px 5%;
  color: #e0e0e0;
  text-align: center;
  max-width: 80%;
  margin: 0 auto 40px auto;
  border-radius: 10px;
}

.about-section h1 {
  font-family: "Playfair Display", serif;
  font-size: 3.5rem;
  color: #ffc107; /* Gyllengul rubrik */
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
}

.about-section h1::after {
  display: block;
  width: 100px;
  height: 4px;
  background-color: #ffc107;
  margin: 10px auto 0 auto;
}

.about-section p {
  font-size: 1.4rem;
  line-height: 1.8;
  max-width: 1000px;
  margin: 10px auto;
  color: #cfd8dc;
}

.contact-section h1 {
  font-family: "Playfair Display", serif;
  font-size: 2.8rem;
  color: #ffc107;
  margin-bottom: 10px;
  position: relative;
}

.contact-section h1::after {
  display: block;
  width: 60px;
  height: 3px;
  background-color: #ffc107;
}

.contact-section p {
  font-size: 1.4rem;
  line-height: 1.9;
  color: #cfd8dc;
}

.contact-section a {
  color: blue; /* Ljus turkos för länk */
  text-decoration: none;
  font-weight: bold;
}

.contact-section .contact-info {
  display: flex;
  flex-direction: column; /* Gör att elementen staplas vertikalt */
  align-items: center;
}

.contact-section .contact-info div {
  text-align: center;
}

.facebook {
  color: blue;
}

.about-section {
  margin-bottom: 40px; /* Minskar avståndet efter Om oss-sektionen */
}
